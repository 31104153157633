import config from './config.js'
import axios from 'axios';
import { ElMessage } from 'element-plus'

const formValidation = {
	
	// 访问本地文件
	// 需要进行映射
	img(url){
		console.log("utls.js img,url=",url);
		if(!url){
			return url;
		}
		url = url.replace(/\\/g,"/");
		if(url.indexOf("http") == 0){
			return url;
		}
		
		// // 本地临时文件
		// // bluesky:2023-10-31
		// if(url.indexOf("tmp") == 0){
		// 	return url;
		// }
		
		let transUrl = url;
		return config.baseUrl() + transUrl;
	},
	
	//通用上传文件方法（从本地上传图片、视频、文件到服务器）
	// bluesky：2022-07-25
	uniUpload(file,opt) { 
		//opt.folderPath 指上传后保存的文件夹，如：“chat/voice/”聊天文件_PCM格式语音，“chat/file/”聊天文件，“userHead/”用户头像，等等
		return new Promise((resolve, reject) => {
			opt = opt || {};
			let url = config.baseUrl() + (opt.url || "/upload/server/uploadFile")+ "?folderPath=" + (opt.folderPath || "")
			// console.log("uniUpload 上传url=" + url);
			//console.log("uniUpload opt=", opt);
			//console.log("uniUpload file=", file);
			
			let formData = new FormData();
			if(opt.dataType == "base64"){
				let base64 = file.base64+"";
				// data:image/png;base64,
				if(base64.indexOf("base64,") >= 0){
					base64 = base64.substring(base64.indexOf(',') + 1);
				}
				 let bytes = window.atob(base64)
				 let ab = new ArrayBuffer(bytes.length)
				  let ia = new Uint8Array(ab)
				  for (let i = 0; i < bytes.length; i++) {
				    ia[i] = bytes.charCodeAt(i)
				  }
				  let name="upload.jpg";
				  let imgs = new File([ab], name, { type: 'image/jpg' })
				  formData.append('file', imgs)
				  //console.log("formData=",formData);
			}
			else{
				formData.append("file", file.file);
			}
			
			axios.post(url,formData).then(res => {
				//console.log("uniUpload success",res);
				let data = res.data;//JSON.parse(res.data)
				//console.log("res.data=", data);
				if (data.code == 200) {
					//console.log("data.code=" + data.code);
					resolve(data.data)
				} else {
					reject(data.data)
				}
			}).catch(res => {
				console.log("uniUpload err",res);
				this.showToast('上传图片/视频/文件失败');
				reject(res)
			});
		})
	},
	showToast(title, icon = 'none', duration = 1500) {
		ElMessage({
		    message: title,
		    type: icon,
			duration: duration,
			center:true,
			offset:300,
		  })
	},
}

export default formValidation
