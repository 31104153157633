const scene = {

    "code": 200,
    "message": "",
    "result":
    {
        "id": "803ade00d134b29110788cf77ce9d4e5",
        "image": "https://test.obs.swqyun.com/system/img_project_default.png?1694676572873",
        "viewAngle": "{\"x\":-878094.7697029657,\"y\":5456198.249091542,\"z\":3175183.518801452,\"heading\":6.283185307179583,\"pitch\":-1.5707962730679959,\"roll\":0}",
        "name": "我的场景_23828172709",
        "jsonUrl": "https://test.obs.swqyun.com/scene/coverage/803ade00d134b29110788cf77ce9d4e5/803ade00d134b29110788cf77ce9d4e5.json"
    }
}
export default scene;