import {
  createRouter,
  createWebHashHistory,
} from 'vue-router';

import properties from '../views/index/properties/index';

function viewsToRoutes(views, prefix = '') {
  const routes = [];
  views.forEach(view => {
    routes.push({
      path: `${prefix}/${view.name}`,
      name: view.name,
      props: true,
      component: view
    })
  });
  return routes;
}

const propertyRoutes = viewsToRoutes(properties, '/properties');

const routes = [
  {
    path: '/',
    name: 'index',
	meta:{
	    title: '现场拍照'
	},
    component: () => import('../views/index/index.vue'),
    children: propertyRoutes
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test/test.vue')
  },
  {
    path: '/final',
    name: 'final',
    component: () => import('../views/final/final.vue')
  },
];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
