import './Swqyun/Static/Widgets/widgets.css';
//import 'ant-design-vue/dist/antd.css';

import { createApp } from 'vue';

import ElementPlus from 'element-plus' //全局引入
import 'element-plus/dist/index.css'

//import Antd from 'ant-design-vue';

import router from '@/router';

import mockXHR from '../mock';
import App from './App.vue';

mockXHR()

let app = createApp(App)

// 引入拍照组件并全局挂载
// blueSky:2024-04-21
import TakePhoto from '@/components/take-photo.vue';
app.component('TakePhoto', TakePhoto);

app.use(router).use(ElementPlus).mount('#app')
