<template>
  <div class="main">
    <!-- 画笔控件 用来拍照 -->
    <canvas style="display: none" ref="canvasDom"/>
    <!-- 播放器，用来播放拍摄的视频 -->
    <video v-if="!imgurl" playsinline="true" webkit-playsinline="true" class="camera_video" ref="videoDom"/>
    <!--  显示照片  -->
    <img v-else :src="imgurl"/>
    
	<div class="btn-list">
		<el-button size="large" type="success" class="btn-take-photo" @click="takePhoto">{{ imgurl ? "重拍" : "拍照" }}</el-button>
		<el-button size="large" type="primary" v-if="imgurl" class="btn-take-photo" @click="success">确定</el-button>
		<el-button size="large" class="btn-take-photo" @click="cancel">取消</el-button>
	</div>
  </div>
</template>


<script setup>
import utils from '../common/ults.js';
	
import {ref, onMounted} from "vue";

const emit = defineEmits(['success','cancel']);

// canvas控件对象
const canvasDom = ref(null);
// video 控件对象
const videoDom = ref(null);
// 照片路径
const imgurl = ref(null);


const openCamera = () => {
  // 检测浏览器是否支持mediaDevices
  if (navigator.mediaDevices) {
    navigator.mediaDevices
        // 开启视频，关闭音频
        .getUserMedia({
			audio: false, 
			//调用前置摄像头
			 //'video':{ 'facingMode': "user" },
			// 后置摄像头使用
			video: { facingMode: { exact: "environment" } }
			//video: true,
		})
        .then((stream) => {
          // 将视频流传入viedo控件
          videoDom.value.srcObject = stream;
          // 播放
          videoDom.value.play();
        })
        .catch((err) => {
          console.log("getUserMedia exception",err);
        });
  } else {
    utils.showToast("该浏览器不支持开启摄像头，请更换最新版浏览器");
  }
};

// 拍照
const takePhoto = () => {
  // 如果已经拍照了就重新启动摄像头
  if (imgurl.value) {
    imgurl.value = null;
    openCamera()
    return;
  }

  // 设置画布大小与摄像大小一致
  canvasDom.value.width = videoDom.value.videoWidth;
  canvasDom.value.height = videoDom.value.videoHeight;
  // 执行画的操作
  canvasDom.value.getContext("2d").drawImage(videoDom.value, 0, 0);
  // 将结果转换为可展示的格式
  imgurl.value = canvasDom.value.toDataURL("image/webp");
  // 关闭摄像头
  stop();
}


// 关闭摄像头
const stop = () => {
  let stream = videoDom.value.srcObject;
  if (!stream) return;
  let tracks = stream.getTracks();
  tracks.forEach((x) => {
    x.stop();
  });

};

// 确定使用
function success(){
	emit('success', {
		base64:imgurl.value
	});
}

// 取消返回
function cancel(){
	emit('cancel', {});
}


// 打开摄像头
onMounted(() => {
  openCamera();
});
</script>

<style scoped>
.main {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  right:0px;
  top:0px;
  bottom:0px;
  z-index: 2000000000000;
  background-color: black;
}

.camera_video {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  right:0px;
  top:0px;
  bottom:0px;
  z-index: 2100000000000;
}

.btn-list{
	position: fixed;
	left:0px;
	right:0px;
	bottom:50px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2200000000000;
	
	.el-button{
		margin-left: 10px !important;
	}
}

.btn-take-photo{
	
	
}
</style>

