var config = {
	env: 10,   
	baseUrl: function() { 
		if (this.env == 0) {//开发版
			return "http://localhost:8080"; 
		} 
		else {//正式服务器版
			return "https://app.mt.xingshantech.cn";
		} 
	},
	// 上传文件路径
	// bluesky:2023-10-03
	uploadUrl:function(){
		return this.baseUrl() + "/upload/server/uploadFile";
	}
};

module.exports = config;
