<template>
  <div>
    <div>
      <span>名称</span>
      <input />
      <br />
      <span>高度</span>
      <input />
      <br />
      <button>确定</button>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';

export default defineComponent({
  name: "Point",
  props: {
    node: Object,
  },
  setup(prop) {
    onMounted(() => {
      const { node } = prop;
      console.log(node);
    });
  },
});
</script>