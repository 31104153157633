import Mock from 'mockjs';

import chart from './chart';
import model from './data/model';
import model_tileset from './data/model_tileset.json';
import point_tileset from './data/point_tileset.json';
import terrain from './data/terrain';
import tms from './data/tms.json';
import kml from './datasource/kml.json';
import scene from './scene';

function mockXHR() {
    //设置代理
    Mock.XHR.prototype.proxy_send = Mock.XHR.prototype.send;
    Mock.XHR.prototype.send = function () {
        if (this.custom.xhr) {
            this.custom.xhr.withCredentials = this.withCredentials || false
            if (this.responseType) {
                this.custom.xhr.responseType = this.responseType
            }
        }
        this.proxy_send(...arguments)
    }

    Mock.mock("/api/chart", 'get', () => chart);
    Mock.mock("/api/scene", 'get', () => scene);


    //倾斜
    Mock.mock('/data/model_tileset', model_tileset);

    //点云
    Mock.mock('/data/point_tileset', point_tileset);

    //倾斜
    Mock.mock('/data/tms', tms)

    //模型
    Mock.mock('/data/model', model),

        //地形
    Mock.mock('/data/terrain/1', terrain[0]);
    Mock.mock('/data/terrain/2', terrain[1]);

    //矢量数据 
    //kml
    Mock.mock('/kml', kml[0]);



}

export default mockXHR;